import {DefaultButton, PrimaryButton} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {ApiContext} from '../api';
import {CustomLayout, DisplayFoldSwitch} from '../common/DisplayFoldSwitch';
import {Toolbar} from '../common/Toolbar';
import {COMMAND_BAR_HEIGHT} from '../consts';
import {FilterFieldContainer, OnSearch} from '../field/FieldComponent';
import {OnChangeFieldValue} from '../field/FieldValue';
import {MaterialIcon} from '../icons/MaterialIcon';
import {
  bodyColor,
  bodyColorAlt,
  borderColorLightest,
  classNameIconMiddle,
  labelColor,
  textColor,
} from '../styles';
import {Actions} from '../types/Action';
import {DisplayToggle} from '../types/DisplayToggle';
import {Field} from '../types/Field';
import {FormValues} from '../types/Form';
import {ListFilter} from '../types/ListFilter';
import {ResourceList} from '../types/ResourceList';
import {SchemaWithoutScreen} from '../types/Schema';
import {Scope} from '../types/Scope';
import {ToolButton} from '../types/ToolButton';

import {RadioWidget} from '../widgets/RadioWidget';

type Props = {
  ctx: ApiContext;
  resourceList: ResourceList;
  filters?: ListFilter[];
  fields: Field[];
  relatedSchemas: {[key: string]: SchemaWithoutScreen};
  scopes?: Scope[];
  onSearch?: (values: FormValues) => void;
  onReset?: () => void;
  actions: Actions;
  buttons?: ToolButton[];
  farButtons?: ToolButton[];
  toggleArea?: string;
  toggle?: DisplayToggle;
  onClickFoldButton?: (layout: CustomLayout) => void;
  defaultParams?: {[key: string]: any};
} & Partial<CustomLayout>;

export type FilterProps = {
  type: string;
  appId: string;
  buttons?: ToolButton[];
  farButtons?: ToolButton[];
  toggle?: DisplayToggle;
  filters?: ListFilter[];
};

export function Filter(props: Props): JSX.Element | null {
  const [values, setValues] = useState<FormValues>(restoreValues(props));

  const schemaId = props.resourceList.schema.id;

  useEffect(() => {
    setValues(restoreValues(props));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaId]);

  const onSearch = (optValues?: FormValues) => {
    if (props.onSearch) {
      props.onSearch({...values, ...optValues});
    }
  };

  const updateScopeValues = (key: string, value: any) => {
    setValues({
      ...values,
      [key]: value,
    });
    onSearch({[key]: value});
  };

  const onReset = async () => {
    const vs = defaultValues(props);
    setValues(vs);

    if (props.onSearch) {
      props.onSearch(vs);
    }

    if (props.onReset) {
      props.onReset();
    }
  };

  const onGetValue = (key: string) => {
    return values[key] || '';
  };

  return (
    <Container>
      <Header>
        <TitleBar>
          <HeaderIcon iconName={'search'} className={classNameIconMiddle} />
          検索条件
        </TitleBar>
        <Toolbar buttons={props.buttons} farButtons={props.farButtons} />
        <DisplayFoldSwitch
          area={props.toggleArea}
          toggle={props.toggle}
          onClick={props.onClickFoldButton}
          customRows={props.customRows}
          customColumns={props.customColumns}
          customComponents={props.customComponents}
        />
      </Header>
      <Body>
        <Scopes
          scopes={props.scopes}
          values={values}
          updateValues={updateScopeValues}
        />
        <FilterElements
          {...props}
          values={values}
          setValues={setValues}
          onSearch={onSearch}
          onGetValue={onGetValue}
        />
      </Body>
      <Footer>
        <Buttons>
          <PrimaryButton
            text={'検索'}
            onClick={() => {
              onSearch();
            }}
            styles={{root: {marginRight: '1rem'}}}
          />
          <DefaultButton text={'条件リセット'} onClick={onReset} />
        </Buttons>
      </Footer>
    </Container>
  );
}

function defaultValues(props: Props) {
  const values: {[key: string]: any} = {};

  for (let f of props.resourceList.schema.fields) {
    if (f.searchDefaultValue) {
      values[f.id] = f.searchDefaultValue;
    }
  }

  if (props.defaultParams) {
    for (let [k, v] of Object.entries(props.defaultParams)) {
      values[k] = v;
    }
  }

  return values;
}

function restoreValues(props: Props) {
  const values = defaultValues(props);
  const keys = collectSearchKeys(props.fields);

  for (let [k, v] of Object.entries(props.resourceList.params)) {
    if (keys.has(k)) {
      values[k] = v;
    }
  }

  return values;
}

function collectSearchKeys(fields: Field[]): Set<string> {
  const keys: string[] = [];

  for (let field of fields) {
    keys.push(...extractSearchKeys(field));
  }

  return new Set(keys);
}

function extractSearchKeys(field: Field): string[] {
  switch (field.type) {
    case 'date':
    case 'date_list':
      return ['_from_date_' + field.id, '_to_date_' + field.id, field.id];
  }

  return [field.id];
}

type ScopesProps = {
  scopes?: Scope[];
  values: FormValues;
  updateValues: (key: string, value: any) => void;
};

function Scopes(props: ScopesProps): JSX.Element | null {
  if (!props.scopes) {
    return null;
  }

  const options = props.scopes
    .filter((scope) => {
      return scope.searchable;
    })
    .map((scope) => {
      return {
        value: scope.id,
        label: scope.label,
      };
    });

  if (options.length === 0) {
    return null;
  }

  const key = '_defined_scope';

  return (
    <FieldContainer key={key}>
      <FieldLabel>{'よく使う条件'}</FieldLabel>
      <RadioWidget
        vertical={true}
        value={String(props.values[key])}
        options={options}
        onChange={(v: any) => {
          props.updateValues(key, v);
        }}
      />
    </FieldContainer>
  );
}

type FilterElementsProps = FilterFieldsProps & {
  filters?: ListFilter[];
};

function FilterElements(props: FilterElementsProps): JSX.Element | null {
  if (props.filters && props.filters.length > 0) {
    return <ListFilters {...props} filters={props.filters} />;
  }

  return <FilterFields {...props} />;
}

type ListFiltersProps = {
  filters: ListFilter[];
  ctx: ApiContext;
  fields: Field[];
  values: FormValues;
  setValues: (values: FormValues) => void;
  resourceList: ResourceList;
  actions: Actions;
  onSearch: OnSearch;
  onGetValue: (key: string) => any;
};

function ListFilters(props: ListFiltersProps): JSX.Element | null {
  const fieldMap = toMap(props.fields);

  //TODO Switch components according to filter type
  return (
    <>
      {props.filters
        .filter((filter) => !!fieldMap[filter.id])
        .map((filter) => {
          const field = fieldMap[filter.id];

          return (
            <FilterField
              {...props}
              field={field}
              label={filter.label}
              key={`field-${field.id}`}
            />
          );
        })}
    </>
  );
}

function toMap(fields: Field[]): {[key: string]: Field} {
  const map: {[key: string]: Field} = {};

  for (let field of fields) {
    map[field.id] = field;
  }

  return map;
}

type FilterFieldsProps = {
  ctx: ApiContext;
  fields: Field[];
  values: FormValues;
  setValues: (values: FormValues) => void;
  resourceList: ResourceList;
  actions: Actions;
  onSearch: OnSearch;
  onGetValue: (key: string) => any;
};

function FilterFields(props: FilterFieldsProps): JSX.Element | null {
  return (
    <>
      {props.fields
        .filter((field) => field.searchable)
        .map((field) => {
          return (
            <FilterField {...props} field={field} key={`field-${field.id}`} />
          );
        })}
    </>
  );
}

type FilterFieldProps = {
  ctx: ApiContext;
  field: Field;
  label?: string;
  values: FormValues;
  setValues: (values: FormValues) => void;
  resourceList: ResourceList;
  actions: Actions;
  onSearch: OnSearch;
  onGetValue: (key: string) => any;
};

function FilterField(props: FilterFieldProps): JSX.Element | null {
  const fns: {[key: string]: OnChangeFieldValue} = {};

  const def: OnChangeFieldValue = (v) => {
    props.setValues({
      ...props.values,
      ...v,
    });
  };

  const onChange: OnChangeFieldValue = fns[props.field.type] || def;

  return (
    <FilterFieldContainer
      {...props}
      value={props.values[props.field.id]}
      onChange={onChange}
    />
  );
}

export function ignoreEmpty(
  values: FormValues,
  notIgnore: string[],
): FormValues {
  const vs: FormValues = {};

  for (let [k, v] of Object.entries(values)) {
    if (notIgnore.indexOf(k) > -1) {
      vs[k] = v;
      continue;
    }

    if (ignore(v)) {
      continue;
    }

    if (Array.isArray(v)) {
      if (v.length === 0) {
        continue;
      }

      if (v.length === 1 && ignore(v[0])) {
        continue;
      }
    }

    vs[k] = v;
  }

  return vs;
}

function ignore(v: any): boolean {
  return v === null || v === undefined || v === '';
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Body = styled.form`
  padding: 1rem;
  overflow: auto;
`;

const FieldContainer = styled.div`
  margin-bottom: 1rem;
`;

const Buttons = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${borderColorLightest};
  background-color: ${bodyColor};
`;

const Header = styled.div`
  height: ${COMMAND_BAR_HEIGHT}px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${borderColorLightest};
  background-color: ${bodyColorAlt};
`;

const TitleBar = styled.div`
  padding-left: 1rem;
  display: flex;
  align-items: center;
  color: ${textColor};
`;

const HeaderIcon = styled(MaterialIcon)`
  margin-right: 0.5rem;
`;

const Footer = styled.div``;

const FieldLabel = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  color: ${labelColor};
  font-size: 0.8rem;
`;
