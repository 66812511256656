import {DefaultButton} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {api, ApiContext} from '../api';
import {Actions} from '../types/Action';
import {Resource} from '../types/Resource';
import {Schema} from '../types/Schema';
import {Buttons, DoneMessage, ErrorMessage} from './CommonForm';
import {ItemForm, Values} from './ItemForm';

type Props = {
  appId: string;
  ids: Set<string>;
  ctx: ApiContext;
  actions: Actions;
  onAfterSave: () => void;
  onCancel: () => void;
  load: boolean;
};

type BatchEditProps = {
  schema: Schema;
  fieldIds: string[];
};

type Status = 'init' | 'submitting' | 'error' | 'done';

export function BatchEditForm({
  appId,
  ids,
  ctx,
  actions,
  onAfterSave,
  onCancel,
  load,
}: Props): JSX.Element | null {
  const [status, setStatus] = useState<Status>('init');
  const [error, setError] = useState<Error | null>(null);
  const [props, setProps] = useState<BatchEditProps | null>(null);
  const intl = useIntl();

  useEffect(() => {
    (async () => {
      if (load && ids.size > 0) {
        const resp = await api.batchEdit(ctx, appId, ids);
        setProps(resp);
      }
    })();
  }, [ctx, appId, ids, load]);

  if (!props) {
    return (
      <Container style={{paddingBottom: '1rem'}}>
        <Buttons>
          <DefaultButton
            onClick={onCancel}
            text={intl.formatMessage({
              id: 'Action.Close',
              defaultMessage: 'Close',
            })}
          />
        </Buttons>
      </Container>
    );
  }

  const fields = props.schema.fields
    .filter((f) => props.fieldIds.includes(f.id))
    .map((f) => ({
      ...f,
      editable: true,
    }));

  const schema = {
    ...props.schema,
    fields: fields,
  };

  const res: Resource = {
    id: 'batch',
    schema: schema,
    details: {
      id: 'batch',
    },
    attachmentFiles: {},
    relatedProxies: {},
    relatedResources: {},
    relatedSchemas: {},
    timestamp: '',
  };

  const onSave = async (values: Values) => {
    try {
      setStatus('submitting');
      await api.batchUpdate(ctx, appId, ids, values);
      setStatus('done');
      onAfterSave();
    } catch (e) {
      setStatus('error');

      if (e instanceof Error) {
        setError(e);
      }
    }
  };

  if (status === 'done') {
    return (
      <Container style={{paddingBottom: '1rem'}}>
        <DoneMessage>
          {intl.formatMessage({
            id: 'Action.Edit.Done',
            defaultMessage: 'Done.',
          })}
        </DoneMessage>
        <Buttons>
          <DefaultButton
            onClick={onCancel}
            text={intl.formatMessage({
              id: 'Action.Close',
              defaultMessage: 'Close',
            })}
          />
        </Buttons>
      </Container>
    );
  }

  return (
    <Container>
      <ItemForm
        ctx={ctx}
        resource={res}
        onCancel={onCancel}
        onSave={onSave}
        actions={actions}
      />
      <ErrorMessage error={error} />
    </Container>
  );
}

const Container = styled.div``;
