import {Sticky, StickyPositionType} from '@fluentui/react';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {api, ApiContext} from '../../api';
import {buildPagerProps} from '../../common/ResourceDetailsTable';
import {Spinner} from '../../common/Spinner';
import {selectComponent} from '../../components/Components';
import {SimpleList, SimpleListProps} from '../../components/SimpleList';
import {Params} from '../../types/Params';
import {ResourceDetails} from '../../types/ResourceDetails';
import {ResourceList} from '../../types/ResourceList';
import {
  AllButton,
  BackButton,
  ButtonContainer,
  Header,
  HeaderTitle,
  HeaderTitleWithButton,
} from './elements';
import {Item} from './Item';
import {Step, Steps} from './Step';

type Props = {
  ctx: ApiContext;
  selected: ResourceDetails[];
  onSelect: (items: ResourceDetails[]) => void;
  onSelectAll: () => void;
  list?: ResourceList;
  filtered: boolean;
  itemType: string;
  itemSubTypes?: string[];
  itemTreeSubTypes?: {[parent: string]: string[]};
  steps: Steps;
  defaultParams: Params;
  forceParams: Params;
  tree: boolean;
  expand: boolean;
  headerTitle: string;
  nameFieldId: string;
  disabledIds: string[];
  capacity?: number;
  stepIndex: number;
  onUpdateStepIndex: (stepIndex: number) => void;
  onUpdateList: (list: ResourceList) => void;
  onUpdateFilter: (list: ResourceList) => void;
  onUpdateFiltered: (filtered: boolean) => void;
};

function useCandidatesState() {
  const [stepRoutes, setStepRoutes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  return {
    stepRoutes,
    setStepRoutes,
    loading,
    setLoading,
  };
}

type State = ReturnType<typeof useCandidatesState>;

export function ItemCandidates(props: Props): JSX.Element {
  const state = useCandidatesState();
  const intl = useIntl();

  if (!props.list || state.loading) {
    return <Spinner />;
  }

  const pagerProps = buildPagerProps(
    props.ctx,
    props.onUpdateList,
    props.list,
    props.defaultParams,
  );
  const selectedIds = props.selected.map((r) => r.id);
  const lockedIds = getLockedIds(props);
  const tree = detectTree(props.tree, props.list);
  const expand = props.expand || props.filtered;
  const headerTitle =
    props.headerTitle ||
    props.list.schema.name ||
    intl.formatMessage({
      id: 'Widget.Item.Candidates',
      defaultMessage: 'Candidates',
    });
  const selectAll = intl.formatMessage({
    id: 'Widget.Item.SelectAll',
    defaultMessage: 'Select All',
  });

  const onRenderRow = (item: ResourceDetails) => (
    <Item
      item={item}
      nameFieldId={props.nameFieldId}
      iconColor={props.list?.schema.iconColor}
    />
  );

  return (
    <>
      <Sticky stickyPosition={StickyPositionType.Header}>
        <Header>
          <CandidateTitle props={props} state={state} title={headerTitle} />
          <SelectAllButton props={props} title={selectAll} />
        </Header>
      </Sticky>
      <SimpleList
        hideHeader={true}
        tools={{
          pagination: true,
          numPaginationButtons: 3,
        }}
        resourceList={props.list}
        onSelect={(item) => {
          onClickCandidate(item, props, state);
        }}
        onRenderRow={onRenderRow}
        {...pagerProps}
        tree={tree}
        disabledIds={props.disabledIds}
        selectedIds={selectedIds}
        lockedIds={lockedIds}
        expandByDefault={expand}
      />
    </>
  );
}

function detectTree(tree: boolean, resourceList?: ResourceList): boolean {
  if (tree) {
    return true;
  }

  if (!resourceList) {
    return false;
  }

  const comp = selectComponent<SimpleListProps>(
    resourceList.schema.screen.components,
    'simple-list',
  );

  if (!comp) {
    return false;
  }

  return comp.tree;
}

type CandidateTitleProps = {
  props: Props;
  state: State;
  title: string;
};

function CandidateTitle({
  props,
  state,
  title,
}: CandidateTitleProps): JSX.Element {
  if (props.steps.length === 0 || props.stepIndex === 0) {
    return <HeaderTitle>{title}</HeaderTitle>;
  }

  return (
    <HeaderTitleWithButton>
      <ButtonContainer
        onClick={() => {
          goBack(props, state);
        }}>
        <BackButton iconName={'chevron_left'} />
      </ButtonContainer>
      {title}
    </HeaderTitleWithButton>
  );
}

type SelectAllButtonProps = {
  props: Props;
  title: string;
};

function SelectAllButton({
  props,
  title,
}: SelectAllButtonProps): JSX.Element | null {
  if (props.capacity) {
    return null;
  }

  if (isOnStep(props)) {
    return null;
  }

  return <AllButton text={title} onClick={props.onSelectAll} />;
}

async function onClickCandidate(
  item: ResourceDetails,
  props: Props,
  state: State,
) {
  if (isOnStep(props)) {
    return goNext(item, props, state);
  }

  if (props.itemTreeSubTypes) {
    const typeTree = props.itemTreeSubTypes[item['sub_type']];

    if (typeTree && props.list) {
      const newSelected = props.list.list.filter((d) => {
        return typeTree.indexOf(d.sub_type) >= 0 && d.parent_id === item.id;
      });

      props.onSelect(newSelected);
      return;
    }
  }

  props.onSelect([item]);
}

function isOnStep(props: Props): boolean {
  return (
    !props.filtered &&
    props.steps.length > 0 &&
    props.stepIndex < props.steps.length
  );
}

async function goNext(item: ResourceDetails, props: Props, state: State) {
  state.setLoading(true);

  const nextStepIndex = props.stepIndex + 1;
  const currentStep = props.steps[props.stepIndex];
  const nextStep = props.steps[nextStepIndex];
  const stepRoutes = [...state.stepRoutes, item.id];

  if (nextStep) {
    const list = await api.list(props.ctx, nextStep.schema_id, {
      ...nextStep.params,
      [currentStep.ref_field_id]: item.id,
      ...props.forceParams,
    });

    const filter = await newFilter(props, list, nextStep);
    update(props, state, list, filter, nextStepIndex, stepRoutes);
    return;
  }

  const list = await api.list(props.ctx, props.itemType, {
    ...props.defaultParams,
    [currentStep.ref_field_id]: item.id,
    ...props.forceParams,
  });

  update(props, state, list, list, nextStepIndex, stepRoutes);
}

async function goBack(props: Props, state: State) {
  state.setLoading(true);

  const prevStepIndex = props.stepIndex - 1;
  const prevStep = props.steps[prevStepIndex];
  const prevPrevStep = props.steps[prevStepIndex - 1];
  const stepRoutes = [...state.stepRoutes];
  stepRoutes.pop(); // discard value
  const id = stepRoutes.pop();

  const params =
    prevPrevStep && id
      ? {
          [prevPrevStep.ref_field_id]: id,
        }
      : {};

  const list = await api.list(props.ctx, prevStep.schema_id, {
    ...prevStep.params,
    ...params,
    ...props.forceParams,
  });

  const filter = await newFilter(props, list, prevStep);

  update(props, state, list, filter, prevStepIndex, stepRoutes);
  props.onUpdateFiltered(false);
  return;
}

async function newFilter(
  props: Props,
  list: ResourceList,
  step: Step,
): Promise<ResourceList> {
  if (step.filter_schema_id) {
    return await api.list(props.ctx, step.filter_schema_id, {});
  }

  return list;
}

function update(
  props: Props,
  state: State,
  list: ResourceList,
  filter: ResourceList,
  stepIndex: number,
  stepRoutes: string[],
): void {
  props.onUpdateList(list);
  props.onUpdateFilter(filter);
  props.onUpdateStepIndex(stepIndex);
  state.setStepRoutes(stepRoutes);
  state.setLoading(false);
}

function getLockedIds(props: Props): string[] {
  const lockedIds: string[] = [];

  if (props.itemSubTypes && props.list) {
    const subTypes = new Set([
      ...props.itemSubTypes,
      ...Object.keys(props.itemTreeSubTypes || {}),
    ]);

    for (let d of props.list.list) {
      if (!subTypes.has(d['sub_type'])) {
        lockedIds.push(d['id']);
      }
    }
  }

  return lockedIds;
}
