import {Panel, PanelType} from '@fluentui/react';
import React from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {colors} from '../colors';
import {useMainTarget} from '../fields/hooks/useItemProps';
import {MaterialIcon} from '../icons/MaterialIcon';
import {bodyColorHovered, borderColorLightest} from '../styles';
import {Actions} from '../types/Action';
import {ResourceDetails} from '../types/ResourceDetails';
import {ResourceList} from '../types/ResourceList';
import {Items} from '../widgets/item/Items';
import {Dialog} from './Dialog';
import {DialogMessage} from './dialogs/DialogMessage';
import {TotalCounter} from './table/TotalCounter';

type Props = {
  isOpen: boolean;
  checkedIds: Set<string>;
  checkedItems: {[id: string]: ResourceDetails};
  onClear: () => void;
  schemaId: string;
  actions: Actions;
};

export function CheckedItems(props: Props): JSX.Element | null {
  const targets = useMainTarget(props.schemaId);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const hideDialog = React.useCallback(() => setDialogVisible(false), []);
  const onClear = props.onClear;
  const doClose = React.useCallback(async () => {
    setDialogVisible(false);
    onClear();
    return true;
  }, [onClear]);

  const onDismiss = React.useCallback(
    (ev?: React.SyntheticEvent | KeyboardEvent) => {
      if (ev) {
        ev.preventDefault();
        setDialogVisible(true);
      }
    },
    [],
  );

  const intl = useIntl();

  if (props.checkedIds.size === 0) {
    return null;
  }

  const items: ResourceDetails[] = [];

  for (let id of props.checkedIds) {
    const item = props.checkedItems[id];

    if (item) {
      items.push(item);
    }
  }

  return (
    <>
      <Panel
        isOpen={props.checkedIds.size > 0 && props.isOpen}
        isBlocking={false}
        onDismiss={onDismiss}
        onRenderHeader={() => {
          if (hidden) {
            return null;
          }

          return (
            <HeaderContainer>
              <HeaderButton
                onClick={() => {
                  setHidden(!hidden);
                }}>
                <MaterialIcon iconName={'chevron_right'} />
              </HeaderButton>
              <HeaderTitle>
                {intl.formatMessage({
                  id: 'CheckedItems.Title',
                  defaultMessage: 'Checked items',
                })}
              </HeaderTitle>
            </HeaderContainer>
          );
        }}
        styles={hidden ? hiddenPanelStyles : panelStyles}
        hasCloseButton={!hidden}
        type={PanelType.custom}
        customWidth={hidden ? '20px' : '300px'}>
        <Container>
          {hidden ? (
            <Handle
              onClick={() => {
                setHidden(!hidden);
              }}>
              <MaterialIcon iconName={'chevron_left'} />
            </Handle>
          ) : (
            <ItemsContainer>
              <ItemsHeader>
                <TotalCounter count={items.length} />
              </ItemsHeader>
              <ItemsBody>
                <Items {...props} items={items} targets={targets} />
              </ItemsBody>
            </ItemsContainer>
          )}
        </Container>
      </Panel>
      <Dialog
        hidden={!dialogVisible}
        onCancel={hideDialog}
        onClose={hideDialog}
        onExecute={doClose}
        executeLabel={intl.formatMessage({
          id: 'Action.Yes',
          defaultMessage: 'Yes',
        })}
        cancelLabel={intl.formatMessage({
          id: 'Action.No',
          defaultMessage: 'No',
        })}
        body={
          <DialogMessage>
            {intl.formatMessage({
              id: 'CheckedItems.Message',
              defaultMessage: 'Are you sure you want to clear the check?',
            })}
          </DialogMessage>
        }
      />
    </>
  );
}

const panelStyles = {
  contentInner: {
    height: '100%',
  },
  content: {
    padding: 0,
    display: 'flex',
    height: '100%',
  },
  scrollableContent: {
    height: '100%',
  },
};

const hiddenPanelStyles = {
  ...panelStyles,
  commands: {
    display: 'none',
  },
};

export type ItemsMap = {[id: string]: ResourceDetails};

export function buildCheckedItems(
  ids: Set<string>,
  list?: ResourceList,
): ItemsMap {
  if (!list) {
    return {};
  }

  const items = itemsOf(list);
  const checkedItems: ItemsMap = {};

  for (let id of ids) {
    const item = items[id];

    if (item) {
      checkedItems[id] = item;
    }
  }

  return checkedItems;
}

function itemsOf(list: ResourceList): ItemsMap {
  const items: ItemsMap = {};

  for (let item of list.list) {
    items[item.id] = item;
  }

  return items;
}

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  flex-grow: 1;
  font-size: 20px;
  font-weight: bold;
  line-height: 27px;
  padding-bottom: 0.5rem;
`;

const HeaderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 0.5rem;

  &:hover {
    background-color: ${bodyColorHovered};
  }
`;

const HeaderTitle = styled.div``;

const Handle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  flex-shrink: 0;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    background-color: ${bodyColorHovered};
  }
`;

const ItemsContainer = styled.div`
  flex-grow: 1;
`;

const ItemsHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  background: ${colors.white};
  border-bottom: 1px solid ${borderColorLightest};
`;

const ItemsBody = styled.div`
  padding: 1rem 1.5rem;
`;
