import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';
import {useApiContext} from '../api';
import {useForceUpdate} from '../hooks/useForceUpdate';
import {useWebSocket} from '../hooks/useWebSocket';
import {Params} from '../types/Params';
import {RelatedResources} from '../types/Resource';
import {ResourceDetails} from '../types/ResourceDetails';
import {Schema} from '../types/Schema';
import {ActionDef} from './scan/ActionButtons';
import {MessageDialog} from './scan/MessageDialog';
import {ScanData} from './scan/ScanData';
import {ScheduleList} from './scan/ScheduleList';
import {UsersAndThings} from './scan/UsersAndThings';

type Props = {
  path: string;
  params: Params;
  schema: Schema;
  item: ResourceDetails;
  relatedResources: RelatedResources;
  userFieldId: string;
  userLabel: string;
  thingFieldId: string;
  thingLabel: string;
  useSchedule: boolean;
  scheduleSchemaId: string;
  scheduleFieldId: string;
  scheduleLabel: string;
  scheduleNameFieldId: string;
  scheduleListParams: Params;
  unknownFieldId: string;
  cancelTimeout: number;
  reloadTimeout: number;
  size: number;
  thingAutoCheck: boolean;
  actions: ActionDef[];
};

export function ScanWidget(props: Props): JSX.Element | null {
  const data = useScanData(props);

  return (
    <Container>
      <MessageDialog data={data} size={props.size} maxWidth={'50vw'} />
      {data.showSchedule ? (
        <ScheduleList {...props} data={data} />
      ) : (
        <UsersAndThings {...props} data={data} />
      )}
    </Container>
  );
}

function useScanData(props: Props): ScanData {
  const forceUpdate = useForceUpdate();
  const ctx = useApiContext();

  const data = useMemo(
    () =>
      new ScanData({
        schemaId: props.schema.id,
        item: props.item,
        relatedResources: props.relatedResources,
        userFieldId: props.userFieldId,
        thingFieldId: props.thingFieldId,
        scheduleFieldId: props.scheduleFieldId,
        scheduleSchemaId: props.scheduleSchemaId,
        scheduleListParams: props.scheduleListParams,
        unknownFieldId: props.unknownFieldId,
        cancelTimeout: props.cancelTimeout,
        reloadTimeout: props.reloadTimeout,
        thingAutoCheck: props.thingAutoCheck,
        forceUpdate,
        ctx,
      }),
    [
      props.schema.id,
      props.item,
      props.relatedResources,
      props.userFieldId,
      props.thingFieldId,
      props.scheduleFieldId,
      props.scheduleSchemaId,
      props.unknownFieldId,
      props.cancelTimeout,
      props.reloadTimeout,
      props.thingAutoCheck,
      forceUpdate,
      ctx,
    ],
  );

  const onMessage = useCallback(
    (e: MessageEvent) => {
      if (e.data === '') {
        window.location.reload();
        return;
      }

      try {
        const json = JSON.parse(e.data);
        data.set(json);
      } catch {
        window.location.reload();
        return;
      }
    },
    [data],
  );

  useWebSocket(props.path, props.params, onMessage);
  return data;
}

// noinspection CssInvalidPropertyValue,CssOverwrittenProperties
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(calc(100 * var(--vh)) - 3rem);
  height: calc(100dvh - 3rem); // for iOS Safari
  position: relative;
  overflow: hidden;
`;
