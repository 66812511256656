import {Persona, PersonaSize} from '@fluentui/react';
import React from 'react';
import styled from 'styled-components';
import {ResourceDetails} from '../../types/ResourceDetails';

type Props = {
  item: ResourceDetails;
  nameFieldId?: string;
  iconColor?: string;
};

function ItemComponent(props: Props) {
  const name = selectName(props.item, props.nameFieldId);

  return (
    <PersonaContainer key={`item-${props.item.id}`}>
      <PersonaOrText
        name={name}
        isDeleted={props.item.is_deleted}
        iconColor={props.item.icon_color || props.iconColor}
      />
    </PersonaContainer>
  );
}

type PersonaOrTextProps = {
  name: string;
  isDeleted?: boolean;
  iconColor?: string;
};

export function makeStyleForDeleted(isDeleted?: boolean) {
  if (isDeleted) {
    return {
      textDecoration: 'line-through',
      textDecorationThickness: '2px',
    };
  }

  return {};
}

function PersonaOrText({
  name,
  isDeleted,
  iconColor,
}: PersonaOrTextProps): JSX.Element {
  const textStyle = makeStyleForDeleted(isDeleted);
  const optProps = iconColor ? {initialsColor: iconColor} : null;

  return (
    <Persona
      title={name}
      text={name}
      size={PersonaSize.size16}
      onRenderInitials={() => null}
      {...optProps}
      styles={{
        details: {
          paddingLeft: 6,
          paddingRight: 0,
        },
        primaryText: textStyle,
      }}
    />
  );
}

function selectName(item: ResourceDetails, fieldId?: string): string {
  const id = fieldId || selectDefaultNameField(item);
  return item[id];
}

function selectDefaultNameField(item: ResourceDetails): string {
  if ('__name__' in item) {
    return '__name__';
  }

  return 'name';
}

export const Item = React.memo(ItemComponent, (props, nextProps) => {
  return props.item.id === nextProps.item.id;
});

const PersonaContainer = styled.div`
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
`;
