import {DefaultButton} from '@fluentui/react';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Data, useApiContext} from '../../api';
import {ApiError} from '../../ApiError';
import {ItemForm, Values} from '../../components/ItemForm';
import {ResponseMessage} from '../../components/RequestForm';
import {Actions} from '../../types/Action';
import {Errors} from '../../types/Errors';
import {Resource} from '../../types/Resource';
import {newErrors} from '../../util';
import {Dialog, DialogButtons, DialogFooter} from '../Dialog';

type Props = {
  shown: boolean;
  onNewRequest: () => Promise<DialogProps>;
  onRequest: (data?: Data) => Promise<ResponseMessage>;
  onForceRequest?: (data?: Data) => Promise<ResponseMessage>;
  onAfterRequest: (resp: ResponseMessage) => void;
  onClose: (resp?: ResponseMessage) => void;
  actions: Actions;
};

type GeneralDialogProps = {
  type: 'general';
  title: string;
  executeButtonLabel: string;
  message: string;
};

type SchemaDialogProps = {
  type: 'input';
  title: string;
  executeButtonLabel: string;
  message: string;
  resource: Resource;
};

type DialogProps = GeneralDialogProps | SchemaDialogProps;

type Status = 'init' | 'doing' | 'done' | 'warning' | 'error';

const defaultDialogProps: DialogProps = {
  type: 'general',
  title: '',
  message: '',
  executeButtonLabel: '実行',
};

export function CommonRequestDialog({
  shown,
  onNewRequest,
  onRequest,
  onForceRequest,
  onAfterRequest,
  onClose,
  actions,
}: Props) {
  const ref = useRef<Dialog>(null);
  const [status, setStatus] = useState<Status>('init');
  const [resp, setResp] = useState<ResponseMessage | undefined>(undefined);
  const [errors, setErrors] = useState<Errors>({});
  const [dialogProps, setDialogProps] =
    useState<DialogProps>(defaultDialogProps);

  useEffect(() => {
    if (shown && ref && ref.current) {
      ref.current.showDialog();
    }
  }, [shown, ref]);

  const onOpen = useCallback(async () => {
    try {
      const props = await onNewRequest();
      setDialogProps(props);
      return true;
    } catch (e) {
      setErrors(getErrors(e));
      return false;
    }
  }, [onNewRequest]);

  const onSave = useCallback(
    async (data?: Data) => {
      try {
        const resp = await onRequest(data);
        onAfterRequest(resp);
        setResp(resp);
        setStatus('done');
        setErrors({});
      } catch (e) {
        const errs = getErrors(e);
        setErrors(errs);

        if (errs['_warning']) {
          setStatus('warning');
        }

        throw e;
      }
    },
    [onRequest, onAfterRequest],
  );

  const onSaveGeneral = async () => {
    try {
      await onSave();
    } catch (ignore) {}

    return false;
  };

  const onForceSave = useCallback(
    async (data?: Data) => {
      if (onForceRequest) {
        try {
          const resp = await onForceRequest(data);
          onAfterRequest(resp);
          setResp(resp);
          setStatus('done');
          setErrors({});
        } catch (e) {
          setErrors(getErrors(e));
          throw e;
        }
      }
    },
    [onForceRequest, onAfterRequest],
  );

  const onForceSaveGeneral = async () => {
    try {
      await onForceSave();
    } catch (ignore) {}

    return false;
  };

  const close = useCallback(() => {
    setDialogProps(defaultDialogProps);
    setResp(undefined);
    setStatus('init');
    setErrors({});
    onClose(resp);
  }, [onClose, resp]);

  const ctx = useApiContext();

  const doClose = () => {
    if (ref.current) {
      ref.current.closeDialog();
    }
  };

  //TODO refine
  if (isInputDialogProps(dialogProps)) {
    return (
      <Dialog
        ref={ref}
        maxWidth={'calc(100vw - 100px)'}
        onOpen={onOpen}
        title={dialogProps.title}
        onClose={close}>
        <MessageContainer style={status === 'done' ? {} : {marginBottom: 0}}>
          {resp?.message || dialogProps.message}
        </MessageContainer>
        {status === 'done' ? (
          <DialogFooter>
            <DialogButtons>
              <DefaultButton onClick={doClose} text={'閉じる'} />
            </DialogButtons>
          </DialogFooter>
        ) : (
          <ItemForm
            ctx={ctx}
            resource={dialogProps.resource}
            onCancel={doClose}
            onSave={async (values: Values) => {
              //TODO support files
              if (status === 'warning') {
                return onForceSave(values);
              } else {
                return onSave(values);
              }
            }}
            actions={actions}
            saveButtonLabel={dialogProps.executeButtonLabel}
          />
        )}
      </Dialog>
    );
  }

  return (
    <Dialog
      ref={ref}
      maxWidth={'calc(100vw - 100px)'}
      onOpen={onOpen}
      onExecute={
        status === 'warning'
          ? onForceSaveGeneral
          : status !== 'done'
          ? onSaveGeneral
          : undefined
      }
      onCancel={close}
      onClose={close}
      errors={errors['_'] || errors['_warning']}
      title={dialogProps.title}
      renderBody={buildGeneralRenderBody(dialogProps.message, resp)}
      executeLabel={dialogProps.executeButtonLabel}
      cancelLabel={status === 'done' ? '閉じる' : undefined}
    />
  );
}

function getErrors(e: any): Errors {
  let errs: Errors = {};

  if (e instanceof ApiError) {
    errs = e.getMessageMap();
  }

  if (Object.keys(errs).length === 0) {
    errs = newErrors('エラーが起きました。');
  }

  return errs;
}

function isInputDialogProps(props: DialogProps): props is SchemaDialogProps {
  return props.type === 'input';
}

function buildGeneralRenderBody(message?: string, resp?: ResponseMessage) {
  return function renderBody(): JSX.Element | null {
    if (resp) {
      return (
        <MessageContainer>
          <p>{resp.message}</p>
        </MessageContainer>
      );
    }

    return (
      <MessageContainer>
        <p>{message}</p>
      </MessageContainer>
    );
  };
}

const MessageContainer = styled.div`
  margin-bottom: 2rem;
  line-height: 1.5;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;
