import {useMemo} from 'react';
import {Data} from '../../api';
import {Action, ActionArgs, Actions} from '../../types/Action';
import {Field} from '../../types/Field';
import {Params} from '../../types/Params';
import {ResourceDetails} from '../../types/ResourceDetails';
import {Schema} from '../../types/Schema';
import {widgetProps} from '../../widgets/common';
import {useActionTarget} from './useActionTarget';

export type ActionAndArgs = {
  action: Action;
  args: ActionArgs;
};

export function useActionAndArgs(
  field: Field,
  schema: Schema,
  item: ResourceDetails,
  actions: Actions,
  actionPropName: string,
  defaultAction: keyof Actions | '',
): ActionAndArgs {
  const key = widgetProps<keyof Actions | ''>(
    field,
    actionPropName,
    defaultAction,
  );

  const action = key === '' ? doNothing : actions[key] || doNothing;

  const params = widgetProps<Params>(
    field,
    'params',
    useMemo(() => ({}), []),
  );

  const data = widgetProps<Data>(
    field,
    'data',
    useMemo(() => ({}), []),
  );

  const target = useActionTarget(field, schema, item);

  const args: ActionArgs = {
    target,
    schema,
    item,
    params,
    data,
    relatedResources: {},
  };

  return {
    action,
    args,
  };
}

export const doNothing = () => {};
