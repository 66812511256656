import {DefaultButton} from '@fluentui/react';
import React, {useRef} from 'react';
import styled from 'styled-components';
import {Dialog} from '../common/Dialog';
import {TargetItemProps} from '../fields/hooks/useItemProps';
import {useMessage} from '../hooks/useMessage';
import {bodyColor, borderColorLight} from '../styles';
import {Actions} from '../types/Action';
import {ResourceDetails} from '../types/ResourceDetails';
import {WidgetStyles} from '../types/WidgetStyles';
import {ClearButton} from './common/ClearButton';
import {Items} from './item/Items';
import {ItemSelectorDialog} from './item/ItemSelectorDialog';

type Props = {
  value: string[];
  onChange: (ids: string[]) => void;
  useIdsToItems: (ids: string[]) => ResourceDetails[];
  useItemsToIds: (items: ResourceDetails[]) => string[];
  onClear: () => void;
  title: string;
  disabledIds: string[];
  actions: Actions;
  capacity?: number;
  targets: TargetItemProps[];
  styles?: WidgetStyles;
  readOnly?: boolean;
};

export function ItemWidget(props: Props): JSX.Element {
  if (props.readOnly) {
    return <ItemOutputWidget {...props} />;
  }

  return <ItemInputWidget {...props} />;
}

function ItemOutputWidget(props: Props): JSX.Element {
  const items = props.useIdsToItems(props.value);

  return (
    <Container>
      {props.value.length > 0 ? <Items {...props} items={items} /> : null}
    </Container>
  );
}

function ItemInputWidget(props: Props): JSX.Element {
  const dialog = useRef<Dialog>(null);
  const items = props.useIdsToItems(props.value);

  return (
    <Container>
      <DefaultButton
        text={useMessage('Widget.Item.Select', 'Select')}
        onClick={() => {
          if (dialog.current) {
            dialog.current.showDialog();
          }
        }}
        styles={{
          root: {
            marginRight: '0.5rem',
            whiteSpace: 'nowrap',
          },
        }}
      />
      <ClearButton onClick={props.onClear} />
      <ItemSelectorDialog
        {...props}
        componentRef={dialog}
        value={items}
        onSelect={(items) => {
          props.onChange(props.useItemsToIds(items));
        }}
      />
      {props.value.length > 0 ? (
        <ItemsContainer>
          <Items {...props} items={items} />
        </ItemsContainer>
      ) : null}
    </Container>
  );
}

const Container = styled.div``;

const ItemsContainer = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid ${borderColorLight};
  background-color: ${bodyColor};
`;
