import {useEffect, useState} from 'react';
import {api, useApiContext} from '../../api';
import {useMessage} from '../../hooks/useMessage';
import {Field, SubTypes, TreeSubTypes, WidgetProps} from '../../types/Field';
import {Params} from '../../types/Params';
import {RelatedSchemas} from '../../types/Resource';
import {ResourceDetails} from '../../types/ResourceDetails';
import {SchemaWithoutScreen} from '../../types/Schema';
import {propsValue, widgetProps} from '../../widgets/common';
import {Steps} from '../../widgets/item/Step';

export type ItemProps = {
  title: string;
  capacity: number;
  disabledIds: string[];
  targets: TargetItemProps[];
};

export type TargetItemProps = {
  steps: Steps;
  itemSchema: SchemaWithoutScreen;
  itemSubTypes?: string[];
  itemTreeSubTypes?: {[parent: string]: string[]};
  candidateTitle: string;
  tree: boolean;
  expand: boolean;
  defaultParams: Params;
  nameFieldId: string;
};

export type TargetItemPropsMap = {[schemaId: string]: TargetItemProps};

export function useItemProps(
  field: Field,
  item?: ResourceDetails,
  relatedSchemas?: RelatedSchemas,
  defaultCapacity?: number,
): ItemProps | null {
  const title = useMessage('Widget.Item.Select', 'Select');
  const capacity = widgetProps<number>(field, 'capacity', defaultCapacity || 0);
  const itemType = field.itemType;
  const itemSchema = useSchema(itemType || '', relatedSchemas);
  const targets = useTargetItemProps(field, itemSchema);

  if (!itemType || !itemSchema) {
    return null;
  }

  const disabledIds = item ? [item.id] : [];

  return {
    title,
    capacity,
    disabledIds,
    targets,
  };
}

export function useMainTarget(schemaId: string): TargetItemProps[] {
  const schema = useSchema(schemaId);

  if (!schema) {
    return [];
  }

  return [buildDefaultTargetItemProps(schema)];
}

function useSchema(
  schemaId?: string,
  relatedSchemas?: RelatedSchemas,
): SchemaWithoutScreen | undefined {
  const init = relatedSchemas ? relatedSchemas[schemaId || ''] : undefined;
  const [schema, setSchema] = useState<SchemaWithoutScreen | undefined>(init);
  const ctx = useApiContext();

  useEffect(() => {
    (async () => {
      if (schemaId && !schema) {
        const s = await api.fetchSchema(ctx, schemaId, {});
        setSchema(s);
      }
    })();
  }, [schemaId, schema, setSchema, ctx]);

  return schema;
}

function useSchemas(
  schema?: SchemaWithoutScreen,
  separateSchema?: boolean,
): SchemaWithoutScreen[] {
  const [schemas, setSchemas] = useState<SchemaWithoutScreen[]>([]);
  const ctx = useApiContext();

  useEffect(() => {
    (async () => {
      if (schema) {
        if (separateSchema) {
          const rs = await api.fetchRelatedSchemas(ctx, schema.id);
          setSchemas(rs);
        } else {
          setSchemas([schema]);
        }
      }
    })();
  }, [schema, separateSchema, ctx]);

  return schemas;
}

function useTargetItemProps(
  field: Field,
  schema?: SchemaWithoutScreen,
): TargetItemProps[] {
  const separateSchema = widgetProps<boolean>(field, 'separate_schema', false);
  const props = widgetProps<{[key: string]: any}>(field, 'separate_props', {});
  const schemas = useSchemas(schema, separateSchema);

  if (schemas.length === 0) {
    return [];
  }

  const result: TargetItemProps[] = [];

  for (let schema of schemas) {
    const itemProps = buildTargetItemProps(
      schema,
      props[schema.id] || field.widgetProps,
      field.itemSubTypes,
      field.itemTreeSubTypes,
    );

    if (itemProps) {
      result.push(itemProps);
    }
  }

  return result;
}

function buildDefaultTargetItemProps(
  itemSchema: SchemaWithoutScreen,
): TargetItemProps {
  return {
    itemSchema,
    candidateTitle: '',
    tree: false,
    expand: false,
    steps: [],
    defaultParams: {},
    nameFieldId: '',
  };
}

function buildTargetItemProps(
  itemSchema: SchemaWithoutScreen,
  props: WidgetProps | undefined,
  defaultItemSubTypes?: SubTypes,
  defaultItemTreeSubTypes?: TreeSubTypes,
): TargetItemProps | null {
  const candidateTitle = propsValue<string>(props, 'candidate_title', '');
  const tree = propsValue<boolean>(props, 'tree', false);
  const expand = propsValue<boolean>(props, 'expand', false);
  const steps = propsValue<Steps>(props, 'steps', []);
  const defaultParams = propsValue<Params>(props, 'params', {});
  const nameFieldId = propsValue<string>(props, 'name_field_id', '');

  const itemSubTypes = propsValue<SubTypes | undefined>(
    props,
    'sub_schema_ids',
    defaultItemSubTypes,
  );
  const itemTreeSubTypes = propsValue<TreeSubTypes | undefined>(
    props,
    'tree_sub_schema_ids',
    defaultItemTreeSubTypes,
  );

  return {
    candidateTitle,
    tree,
    expand,
    steps,
    defaultParams,
    nameFieldId,
    itemSchema,
    itemSubTypes,
    itemTreeSubTypes,
  };
}

export function buildTargetItemPropsMap(
  targets: TargetItemProps[],
): TargetItemPropsMap {
  const map: TargetItemPropsMap = {};

  for (let target of targets) {
    map[target.itemSchema.id] = target;
  }

  return map;
}
