import {api, ApiContext} from '../../api';
import {ApiError} from '../../ApiError';
import {Params} from '../../types/Params';
import {RelatedResources} from '../../types/Resource';
import {ResourceDetails} from '../../types/ResourceDetails';
import {ResponseDialog} from '../../types/ResponseDialog';
import {array} from '../../util';

type SimpleResource = {
  details: ResourceDetails;
  relatedResources: RelatedResources;
};

type Arg = {
  schemaId: string;
  item: ResourceDetails;
  relatedResources: RelatedResources;
  userFieldId: string;
  thingFieldId: string;
  scheduleFieldId: string;
  scheduleSchemaId: string;
  scheduleListParams: Params;
  unknownFieldId: string;
  cancelTimeout: number;
  reloadTimeout: number;
  thingAutoCheck: boolean;
  forceUpdate: () => void;
  ctx: ApiContext;
};

export class ScanData {
  private readonly schemaId: string;
  private readonly id: string;
  private readonly userFieldId: string;
  private userId: string;
  private users: ResourceDetails[];
  private readonly thingFieldId: string;
  private thingIds: string[];
  private things: ResourceDetails[];
  private readonly scheduleFieldId: string;
  private scheduleIds: string[];
  private schedules: ResourceDetails[];
  private readonly forceUpdate: () => void;
  private readonly ctx: ApiContext;
  private requesting: boolean;
  private responseDialog: ResponseDialog | null;
  private cancelTimerId: number | null;
  private readonly cancelTimeout: number;
  private reloadTimerId: number | null;
  private readonly reloadTimeout: number;
  private readonly scheduleSchemaId: string;
  private readonly scheduleListParams: Params;
  showSchedule: boolean = false;
  private readonly unknownFieldId: string;
  private unknownIds: string[];
  private readonly thingAutoCheck: boolean;

  constructor(arg: Arg) {
    this.schemaId = arg.schemaId;
    this.id = arg.item.id;
    this.userFieldId = arg.userFieldId;
    this.users = selectItems(arg.item, arg.relatedResources, this.userFieldId);
    this.userId = this.users[0]?.id ?? '';

    this.thingFieldId = arg.thingFieldId;
    this.things = selectItems(
      arg.item,
      arg.relatedResources,
      this.thingFieldId,
    );
    this.thingIds = arg.thingAutoCheck ? this.things.map((t) => t.id) : [];

    this.forceUpdate = arg.forceUpdate;
    this.ctx = arg.ctx;
    this.requesting = false;
    this.responseDialog = null;
    this.cancelTimerId = null;
    this.cancelTimeout = arg.cancelTimeout;
    this.reloadTimerId = null;
    this.reloadTimeout = arg.reloadTimeout;
    this.thingAutoCheck = arg.thingAutoCheck;

    this.scheduleFieldId = arg.scheduleFieldId;
    this.scheduleSchemaId = arg.scheduleSchemaId;
    this.scheduleListParams = arg.scheduleListParams;
    this.scheduleIds = [];
    this.schedules = [];

    this.unknownFieldId = arg.unknownFieldId;
    this.unknownIds = arg.item[arg.unknownFieldId] || [];

    this.startReloadTimer();

    if (this.hasScannedData()) {
      this.startCancelTimer();
    }
  }

  set(res: SimpleResource) {
    this.stopAllTimers();
    const rr = res.relatedResources;

    const newUsers = selectItems(res.details, rr, this.userFieldId);
    const newUserId = selectNewUserId(this.userId, this.users, newUsers);

    const newThings = selectItems(res.details, rr, this.thingFieldId);
    const newThingIds = selectNewThingIds(
      this.thingIds,
      this.things,
      newThings,
      this.thingAutoCheck,
    );

    this.users = newUsers;
    this.userId = newUserId;
    this.things = newThings;
    this.thingIds = newThingIds;

    this.unknownIds = res.details[this.unknownFieldId] || [];

    this.startCancelTimer();
    this.forceUpdate();
  }

  hasScannedData(): boolean {
    return (
      this.getUsers().length > 0 ||
      this.getThings().length > 0 ||
      this.getUnknown().length > 0
    );
  }

  setUserId = (id: string) => {
    this.userId = id;
    this.forceUpdate();
  };

  getUserId(): string {
    return this.userId;
  }

  getUsers(): ResourceDetails[] {
    return this.users;
  }

  setThingIds = (ids: string[]) => {
    this.thingIds = ids;
    this.forceUpdate();
  };

  getThingIds(): string[] {
    return this.thingIds;
  }

  getThings(): ResourceDetails[] {
    return this.things;
  }

  setScheduleIds = (ids: string[]) => {
    this.scheduleIds = ids;
    this.forceUpdate();
  };

  getScheduleIds(): string[] {
    return this.scheduleIds;
  }

  getSchedules(): ResourceDetails[] {
    return this.schedules;
  }

  getUnknown(): string[] {
    return this.unknownIds;
  }

  isRequesting(): boolean {
    return this.requesting;
  }

  private startRequesting() {
    this.stopAllTimers();
    this.requesting = true;
    this.forceUpdate();
  }

  goNext = async () => {
    this.stopAllTimers();
    this.showSchedule = true;
    this.forceUpdate();
    this.startCancelTimer();
  };

  goBack = async () => {
    this.stopAllTimers();
    this.showSchedule = false;
    this.scheduleIds = [];
    this.forceUpdate();
    this.startCancelTimer();
  };

  doIn = async () => {
    return await this.doAction('in');
  };

  doOut = async () => {
    return await this.doAction('out');
  };

  doTemp = async () => {
    return await this.doAction('temp');
  };

  doAction = async (action: string) => {
    if (this.requesting) {
      return;
    }

    this.startRequesting();

    try {
      const res = await api.request(this.ctx, this.schemaId, this.id, {
        action: action,
        [this.userFieldId]: this.getUserId(),
        [this.thingFieldId]: this.getThingIds(),
        [this.scheduleFieldId]: this.getScheduleIds(),
      });

      this.responseDialog = res.dialog;
      this.startReloadTimer();
    } catch (e) {
      if (e instanceof ApiError) {
        this.responseDialog = e.getDialog();
      }

      this.startCancelTimer();
    } finally {
      this.requesting = false;
      this.forceUpdate();
    }
  };

  doCancel = async () => {
    if (this.requesting) {
      return;
    }

    this.startRequesting();

    try {
      await api.request(this.ctx, this.schemaId, this.id, {
        action: 'cancel',
      });
    } catch (e) {
      console.log(e);
    } finally {
      const pong = await api.ping(this.ctx);

      if (pong) {
        window.location.reload();
        return;
      }

      this.requesting = false;
      this.responseDialog = null;
      this.forceUpdate();
    }
  };

  listSchedule = async () => {
    this.stopAllTimers();

    try {
      const list = await api.list(this.ctx, this.scheduleSchemaId, {
        ...this.scheduleListParams,
        [this.userFieldId]: this.userId,
        [this.thingFieldId]: this.thingIds,
      });
      this.schedules = list.list;
    } finally {
      this.forceUpdate();
      this.startCancelTimer();
    }
  };

  canSendRequest = (): boolean => {
    return (
      !this.requesting &&
      (this.getUserId() !== '' || this.userNotRequired()) &&
      this.getThingIds().length > 0
    );
  };

  userNotRequired(): boolean {
    return this._allThingsTrue('_user_not_required');
  }

  scheduleNotRequired(): boolean {
    return this._allThingsTrue('_schedule_not_required');
  }

  _allThingsTrue(fieldId: string): boolean {
    const thingMap = this.makeThingMap();

    for (let thingId of this.getThingIds()) {
      const thing = thingMap[thingId];

      if (thing && !thing[fieldId]) {
        return false;
      }
    }

    return true;
  }

  makeThingMap(): {[key: string]: ResourceDetails} {
    const map: {[key: string]: ResourceDetails} = {};

    for (let thing of this.getThings()) {
      map[thing.id] = thing;
    }

    return map;
  }

  getResponseDialog(): ResponseDialog | null {
    return this.responseDialog;
  }

  stopAllTimers() {
    this.stopCancelTimer();
    this.stopReloadTimer();
  }

  startCancelTimer() {
    this.stopCancelTimer();
    this.cancelTimerId = window.setTimeout(() => {
      this.doCancel();
    }, this.cancelTimeout * 1000);
  }

  stopCancelTimer() {
    if (this.cancelTimerId) {
      window.clearTimeout(this.cancelTimerId);
      this.cancelTimerId = null;
    }
  }

  startReloadTimer() {
    this.stopReloadTimer();
    this.reloadTimerId = window.setTimeout(() => {
      window.location.reload();
    }, this.reloadTimeout * 1000);
  }

  stopReloadTimer() {
    if (this.reloadTimerId) {
      window.clearTimeout(this.reloadTimerId);
      this.reloadTimerId = null;
    }
  }

  clear() {
    this.stopAllTimers();
    this.responseDialog = null;
    this.forceUpdate();
    this.startCancelTimer();
  }
}

function selectItems(
  item: ResourceDetails,
  rr: RelatedResources,
  fid: string,
): ResourceDetails[] {
  const vs = array(item[fid]);

  if (!vs) {
    return [];
  }

  return vs.map((v) => rr[v]);
}

function selectNewUserId(
  currId: string,
  currUsers: ResourceDetails[],
  newUsers: ResourceDetails[],
): string {
  if (currId) {
    return currId;
  }

  const [first] = minus(newUsers, currUsers);
  return first ?? '';
}

function selectNewThingIds(
  currIds: string[],
  currThings: ResourceDetails[],
  newThings: ResourceDetails[],
  auto: boolean,
): string[] {
  if (!auto) {
    return [...currIds];
  }

  const addedIds = minus(newThings, currThings);
  return [...currIds, ...addedIds];
}

function minus(a: ResourceDetails[], b: ResourceDetails[]): Set<string> {
  const ids1 = new Set<string>(a.map((x) => x.id));
  const ids2 = b.map((x) => x.id);

  for (let id of ids2) {
    ids1.delete(id);
  }

  return ids1;
}
