import React, {KeyboardEventHandler} from 'react';
import {Field} from '../types/Field';
import {OnChangeFn} from '../widgets/CheckboxWidget';
import {NumberWidget} from '../widgets/NumberWidget';
import {useOnChange} from './hooks/useOnChange';
import {useTextProps} from './hooks/useTextProps';

type Props = {
  value: any;
  onChange: OnChangeFn;
  field: Field;
  readOnly?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export function NumberField(props: Props): JSX.Element | null {
  const onChange = useOnChange(props.onChange, props.field.id);
  const textProps = useTextProps(props.field, false);
  const min = props.field.minValue;
  const max = props.field.maxValue;

  return (
    <NumberWidget
      {...props}
      {...textProps}
      onChange={onChange}
      min={min}
      max={max}
    />
  );
}
